const AboutUsInfo = () => {
  return (
    <>
      <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
        <div className="text-center">
          <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">About Us</h1>
        </div>
        <div className="w-full md:w-[90%] m-auto">
          <span className="text-base text-zinc-400 font-semibold">
            Our experts with over 19 years experience will repair your window in a professional and timely manner.
          </span>
        </div>
        <div className="pt-5 w-full md:w-[90%] m-auto">
          <span className="text-base text-zinc-400 font-semibold">
            Our team at Avondale Auto Glass is committed to upholding our porpuse to make a difference with real care for our
            customers. We do this by consistently providing our customers with our superior service developing auto glass
            &quot;Installation and window tint by providing ceramic glass film technology and ensuring every job across the company
            is carried our with the same standards&quot;.
          </span>
        </div>
      </div>
    </>
  );
};

export default AboutUsInfo;