function validateMail(mail) {
  if ((/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail))){
    return true;
  }
  return false;
}

function validatePassword(password) {
  if ((/^(?=(?:.*\d))(?=.*[A-Z])(?=.*[a-z])(?=.*[.,*!?¿¡/#$%&])\S{8,64}$/).test(password)) {
    return true;
  }
  return false;
}

function validateString(str) {
  if ((/^[a-zA-ZáéíóúÁÉÍÓÚñÑ /s]*$/.test(str))) {
    return true;
  }
  return false;
}

function validatePostalCode(nbr) {
  if((/^\d{5}$/.test(nbr)) ) {
    return true;
  }
  return false;
}

function validatePhone(nbr) {
  if((/^\d{10}$/.test(nbr)) ) {
    return true;
  }
  return false;
}

function validateNumberWidthDecimals(nbr) {
  if ((/^[0-9]+([.][0-9]+)?$/).test(nbr)) {
    return true;
  }
  return false;
}

function validateNumber(nbr) {
  if ((/^[0-9]*$/).test(nbr)) {
    return true;
  }
  return false;
}

function validateCardNumber(nbr) {
  const VISA = /^4[0-9]{3}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
  const MASTERCARD = /^5[1-5][0-9]{2}-?[0-9]{4}-?[0-9]{4}-?[0-9]{4}$/;
  const AMEX = /^3[47][0-9]{13}$/;
  const CABAL = /^(6042|6043|6044|6045|6046|5896){4}[0-9]{12}$/;
  const NARANJA = /^(589562|402917|402918|527571|527572|0377798|0377799)[0-9]*$/;

  if (VISA.test(nbr) || MASTERCARD.test(nbr) || AMEX.test(nbr) || CABAL.test(nbr) || NARANJA.test(nbr)) {
    return true;
  }
  return false;
}

function validateDateCardNumber(nbr) {
  if ((/^\d{2}\/\d{2}$/).test(nbr)) {
    return true;
  }
  return false;
}

// Login form
const validateLogin = (userModel) => {
  if (!validateMail(userModel.email)) return { status: false, msg: "Please check the email field" };

  if (String(userModel.password).length < 8) return { status: false, msg: "The password must be greater than 8 digits" };

  return {status: true, msg: "All fields are correct" };
};

const validateConfirmPassword = (passwordModel) => {
  let msgError = "";

  if (!validatePassword(passwordModel.password) || String(passwordModel.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePassword(passwordModel.confirmPassword) || String(passwordModel.confirmPassword).trim().length === 0) {
    if (msgError !== "") msgError += ", Confirm password";
    else msgError += "Confirm password";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateProfile = (profileModel) => {
  let msgError = "";

  if (!validateString(profileModel.names) || String(profileModel.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateString(profileModel.lastNames) || String(profileModel.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last names";
    else msgError += "Last names";
  }

  if (!validateMail(profileModel.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (profileModel?.password && !validatePassword(profileModel?.password)) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(profileModel.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }
  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateNewUserRetail = (retailUser) => {
  let msgError = "";

  if (!validateString(retailUser.names) || String(retailUser.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateString(retailUser.lastNames) || String(retailUser.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last names";
    else msgError += "Last names";
  }

  if (!validateMail(retailUser.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePassword(retailUser.password) || String(retailUser.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(retailUser.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validatePostalCode(retailUser.zipCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateNewUserWholesaler = (wholesalerUser) => {
  let msgError = "";

  if (!validateString(wholesalerUser.workshopName) || String(wholesalerUser.workshopName).trim().length === 0) {
    if (msgError !== "") msgError += ", Workshop Name";
    else msgError += "Workshop Name";
  }

  if (!validateString(wholesalerUser.names) || String(wholesalerUser.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateMail(wholesalerUser.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePassword(wholesalerUser.password) || String(wholesalerUser.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(wholesalerUser.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (String(wholesalerUser.invoice).trim().length === 0 || !validateNumber(wholesalerUser.invoice)) {
    if (msgError !== "") msgError += ", Invoice";
    else msgError += "Invoice";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateAdministrators = (administratorModel) => {
  let msgError = "";

  if (!validateString(administratorModel.names) || String(administratorModel.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Name";
    else msgError += "Name";
  }

  if (!validateString(administratorModel.lastNames) || String(administratorModel.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last name";
    else msgError += "Last name";
  }

  if (!validateMail(administratorModel.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePassword(administratorModel.password) || String(administratorModel.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(administratorModel.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateVendor = (vendor) => {
  let msgError = "";

  if (!validateString(vendor.name) || String(vendor.name).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateMail(vendor.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePhone(vendor.phone)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (String(vendor.address.state).trim().length === 0) {
    if (msgError !== "") msgError += ", State";
    else msgError += "State";
  }

  if (!validatePostalCode(vendor.address.postalCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  if (String(vendor.address.locality).trim().length === 0) {
    if (msgError !== "") msgError += ", Locality";
    else msgError += "Locality";
  }

  if (String(vendor.address.street).trim().length === 0) {
    if (msgError !== "") msgError += ", Street";
    else msgError += "Street";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateRetail = (retailUser) => {
  let msgError = "";

  if (!validateString(retailUser.names) || String(retailUser.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateString(retailUser.lastNames) || String(retailUser.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last names";
    else msgError += "Last names";
  }

  if (!validateMail(retailUser.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePassword(retailUser.password) || String(retailUser.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(retailUser.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validatePostalCode(retailUser.zipCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateWholesaler = (wholesalerUser) => {
  let msgError = "";

  if (!validateString(wholesalerUser.workshopName) || String(wholesalerUser.workshopName).trim().length === 0) {
    if (msgError !== "") msgError += ", Workshop Name";
    else msgError += "Workshop Name";
  }

  if (!validateString(wholesalerUser.names) || String(wholesalerUser.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateString(wholesalerUser.lastNames)) {
    if (msgError !== "") msgError += ", Last names";
    else msgError += "Last names";
  }

  if (!validateMail(wholesalerUser.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePassword(wholesalerUser.password) || String(wholesalerUser.password).trim().length === 0) {
    if (msgError !== "") msgError += ", Password";
    else msgError += "Password";
  }

  if (!validatePhone(wholesalerUser.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validatePostalCode(wholesalerUser.zipCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  if (String(wholesalerUser.invoice).trim().length === 0 || !validateNumber(wholesalerUser.invoice)) {
    if (msgError !== "") msgError += ", Invoice";
    else msgError += "Invoice";
  }

  if (!validateNumberWidthDecimals(wholesalerUser.availableCredit)) {
    if (msgError !== "") msgError += ", Available credit";
    else msgError += "Available credit";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateProducts = (productModel) => {
  let msgError = "";

  if (String(productModel.name).trim().length === 0) {
    if (msgError !== "") msgError += ", Product name";
    else msgError += "Product name";
  }

  if (String(productModel.nags).trim().length === 0) {
    if (msgError !== "") msgError += ", Nags";
    else msgError += "Nags";
  }

  if (!validateNumberWidthDecimals(productModel.price)) {
    if (msgError !== "") msgError += ", Price";
    else msgError += "Price";
  }

  if (!validateNumber(productModel.year)) {
    if (msgError !== "") msgError += ", Year";
    else msgError += "Year";
  }

  if (String(productModel.barcode1).trim().length === 0) {
    if (msgError !== "") msgError += ", Barcode 1";
    else msgError += "Barcode 1";
  }

  if (String(productModel.brand).trim().length === 0) {
    if (msgError !== "") msgError += ", Mark";
    else msgError += "Mark";
  }

  if (String(productModel.model).trim().length === 0) {
    if (msgError !== "") msgError += ", Model";
    else msgError += "Model";
  }

  if (String(productModel.features).trim().length === 0) {
    if (msgError !== "") msgError += ", Features";
    else msgError += "Features";
  }

  if (String(productModel.description).trim().length === 0) {
    if (msgError !== "") msgError += ", Description";
    else msgError += "Description";
  }

  if (String(productModel.note).trim().length === 0) {
    if (msgError !== "") msgError += ", Note";
    else msgError += "Note";
  }

  if (!validateNumber(productModel.totalQuantity)) {
    if (msgError !== "") msgError += ", Total Quantity";
    else msgError += "Total Quantity";
  }

  // if (!validateNumber(productModel.serviceTime)) {
  //     if (msgError !== "") msgError += ", Service time";
  //     else msgError += "Service time";
  // }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateInventoryAdd = (inventoryModel) => {
  let msgError = "";

  if (!validateNumberWidthDecimals(inventoryModel.cost)) {
    if (msgError !== "") msgError += ", Cost";
    else msgError += "Cost";
  }

  if (!validateNumberWidthDecimals(inventoryModel.sellerPrice)) {
    if (msgError !== "") msgError += ", Seller price";
    else msgError += "Seller price";
  }

  if (!validateNumberWidthDecimals(inventoryModel.resellerPrice)) {
    if (msgError !== "") msgError += ", Reseller price";
    else msgError += "Reseller price";
  }

  if (!validateNumber(inventoryModel.maxStock)) {
    if (msgError !== "") msgError += ", Maximum stock";
    else msgError += "Maximum stock";
  }

  if (!validateNumber(inventoryModel.minStock)) {
    if (msgError !== "") msgError += ", Minimum stock";
    else msgError += "Minimum stock";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateInventoryTransfer = (inventoryModel) => {
  let msgError = "";

  if (!validateNumberWidthDecimals(inventoryModel.fees)) {
    if (msgError !== "") msgError += ", FEES";
    else msgError += "FEES";
  }

  if (!validateNumberWidthDecimals(inventoryModel.freight)) {
    if (msgError !== "") msgError += ", FREIGHT";
    else msgError += "FREIGHT";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateWarehouse = (warehouse) => {
  let msgError = "";

  if (!validateString(warehouse.name) || String(warehouse.name).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validatePhone(warehouse.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validatePostalCode(warehouse.address.postalCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  if (String(warehouse.address.state).trim().length === 0) {
    if (msgError !== "") msgError += ", State";
    else msgError += "State";
  }

  if (String(warehouse.address.locality).trim().length === 0) {
    if (msgError !== "") msgError += ", Locality";
    else msgError += "Locality";
  }

  if (String(warehouse.address.street).trim().length === 0) {
    if (msgError !== "") msgError += ", Street";
    else msgError += "Street";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateQuote = () => {
  let msgError = "";

  // if (String(quote.names).trim().length === 0) {
  //     if (msgError !== "") msgError += ", Names";
  //     else msgError += "Names";
  // }

  // if (!validatePostalCode(quote.zipCode)) {
  //     if (msgError !== "") msgError += ", ZIP code";
  //     else msgError += "ZIP code";
  // }

  // if (!validateMail(quote.email)) {
  //     if (msgError !== "") msgError += ", Email";
  //     else msgError += "Email";
  // }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateQuotePhoto = (quote) => {
  let msgError = "";

  if (!validateString(quote.names) || String(quote.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Names";
    else msgError += "Names";
  }

  if (!validateString(quote.lastNames) || String(quote.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last Names";
    else msgError += "Last Names";
  }

  if (!validateMail(quote.email) || String(quote.email).trim().length === 0) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (!validatePhone(quote.phoneNumber) || String(quote.phoneNumber).trim().length === 0) {
    if (msgError !== "") msgError += ", Phone Number";
    else msgError += "Phone Number";
  }

  if (String(quote.description).trim().length === 0) {
    if (msgError !== "") msgError += ", Description";
    else msgError += "Description";
  }

  if (String(quote?.companyInsurance).trim().length === 0) {
    if (msgError !== "") msgError += ", Company Insurance";
    else msgError += "Company Insurance";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateAddress = (address) => {
  let msgError = "";

  if (String(address.street).trim().length === 0) {
    if (msgError !== "") msgError += ", Street";
    else msgError += "Street";
  }

  // if (String(address.exteriorNumber).trim().length === 0) {
  //     if (msgError !== "") msgError += ", N. Outside";
  //     else msgError += "N. Outside";
  // }

  // if (String(address.interiorNumber).trim().length === 0) {
  //     if (msgError !== "") msgError += ", N. Inside";
  //     else msgError += "N. Inside";
  // }

  if (!validatePostalCode(address.postalCode)) {
    if (msgError !== "") msgError += ", ZIP code";
    else msgError += "ZIP code";
  }

  // if (String(address.btwStreetOne).trim().length === 0) {
  //     if (msgError !== "") msgError += ", Street 1";
  //     else msgError += "Street 1";
  // }

  // if (String(address.btwStreetTwo).trim().length === 0) {
  //     if (msgError !== "") msgError += ", Street 2";
  //     else msgError += "Street 2";
  // }

  if (String(address.suburb).trim().length === 0) {
    if (msgError !== "") msgError += ", State";
    else msgError += "State";
  }

  if (String(address.city).trim().length === 0) {
    if (msgError !== "") msgError += ", City";
    else msgError += "City";
  }

  // if (String(address.country).trim().length === 0) {
  //     if (msgError !== "") msgError += ", Country";
  //     else msgError += "Country";
  // }

  // if (String(address.references).trim().length === 0) {
  //     if (msgError !== "") msgError += ", References";
  //     else msgError += "References";
  // }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };

};

const validateCreditCard = (creditCard) => {
  let msgError = "";

  if (!validateCardNumber(creditCard.cardNumber) || !validateNumber(creditCard.cardNumber) || (String(creditCard.cardNumber).length < 15 || String(creditCard.cardNumber).length > 16)) {
    if (msgError !== "") msgError += ", Card number";
    else msgError += "Card number";
  }

  if (!validateString(creditCard.nameofOwner) || String(creditCard.nameofOwner).trim().length === 0) {
    if (msgError !== "") msgError += ", Name of owner";
    else msgError += "Name of owner";
  }

  if (!validateDateCardNumber(creditCard.validity) || Number(String(creditCard.validity).substring(0,2)) > 12 || Number(String(creditCard.validity).substring(0,2)) === 0) {
    if (msgError !== "") msgError += ", Validity";
    else msgError += "Validity";
  }

  if (!validateNumber(creditCard.cvv) || (((Number(String(creditCard.cardNumber).substring(0,1)) === 3) && String(creditCard.cvv).length !== 4) || (Number(String(creditCard.cardNumber).substring(0,1)) !== 3 && String(creditCard.cvv).length !== 3))) {
    // if (!validateNumber(creditCard.cvv) || ((String(creditCard.cvv).length < 3 && String(creditCard.cvv).length > 4))) {
    if (msgError !== "") msgError += ", CVV";
    else msgError += "CVV";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateCarInsurance = (carInsurance) => {
  let msgError = "";

  // if (!validateNumber(carInsurance.numberOfInsurance) || (String(carInsurance.numberOfInsurance).length < 8 || String(carInsurance.numberOfInsurance).length > 10)) {
  if (String(carInsurance.numberOfInsurance).trim().length === 0) {
    if (msgError !== "") msgError += ", Policy number";
    else msgError += "Policy number";
  }

  if (String(carInsurance.companyInsurance).trim().length === 0) {
    if (msgError !== "") msgError += ", Company";
    else msgError += "Company";
  }

  if (!validatePhone(carInsurance.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (String(carInsurance.contactName).trim().length === 0) {
    if (msgError !== "") msgError += ", Contact name";
    else msgError += "Contact name";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateCarInsuranceAux = (carInsurance) => {
  let msgError = "";

  if (String(carInsurance.numberOfInsurance).trim().length === 0) {
    // if (!validateNumber(carInsurance.numberOfInsurance) || (String(carInsurance.numberOfInsurance).length < 8 || String(carInsurance.numberOfInsurance).length > 10)) {
    if (msgError !== "") msgError += ", Policy number";
    else msgError += "Policy number";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateFormReparation = (repairModel) => {
  let msgError = "";

  if (!validateString(repairModel.names) || String(repairModel.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Name";
    else msgError += "Name";
  }

  if (!validateString(repairModel.lastNames) || String(repairModel.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last name";
    else msgError += "Last name";
  }

  if (!validatePhone(repairModel.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validateMail(repairModel.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (String(repairModel.description).trim().length === 0) {
    if (msgError !== "") msgError += ", Description";
    else msgError += "Description";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateInformation = (informationModel) => {
  let msgError = "";

  if (!validateString(informationModel.names) || String(informationModel.names).trim().length === 0) {
    if (msgError !== "") msgError += ", Name";
    else msgError += "Name";
  }

  if (!validateString(informationModel.lastNames) || String(informationModel.lastNames).trim().length === 0) {
    if (msgError !== "") msgError += ", Last Name";
    else msgError += "Last Name";
  }

  if (!validatePhone(informationModel.phoneNumber)) {
    if (msgError !== "") msgError += ", Phone number";
    else msgError += "Phone number";
  }

  if (!validateMail(informationModel.email)) {
    if (msgError !== "") msgError += ", Email";
    else msgError += "Email";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

const validateInsurer = (insurerModel) => {
  let msgError = "";

  if (String(insurerModel.name).trim().length === 0) {
    if (msgError !== "") msgError += ", Name";
    else msgError += "Name";
  }

  if (!validateNumber(insurerModel.discountRegular)) {
    if (msgError !== "") msgError += ", Discount regular";
    else msgError += "Discount regular";
  }

  if (!validateNumber(insurerModel.discountHeated)) {
    if (msgError !== "") msgError += ", Discount heated";
    else msgError += "Discount heated";
  }

  if (!validateNumber(insurerModel.hour)) {
    if (msgError !== "") msgError += ", Hour";
    else msgError += "Hour";
  }

  if (!validateNumber(insurerModel.kitRegular)) {
    if (msgError !== "") msgError += ", Kit regular";
    else msgError += "Kit regular";
  }

  if (!validateNumber(insurerModel.kitHighModulus)) {
    if (msgError !== "") msgError += ", Kit high modulus";
    else msgError += "Kit high modulus";
  }

  if (!validateNumber(insurerModel.dynamicCalibration)) {
    if (msgError !== "") msgError += ", Dynamic Calibration";
    else msgError += "Dynamic Calibration";
  }

  if (!validateNumber(insurerModel.staticCalibration)) {
    if (msgError !== "") msgError += ", Static Calibration";
    else msgError += "Static Calibration";
  }

  if (!validateNumber(insurerModel.dualCalibration)) {
    if (msgError !== "") msgError += ", Dual Calibration";
    else msgError += "Dual Calibration";
  }

  if (msgError === "") {
    return { status: true, msg: "All fields are correct" };
  }

  return { status: false, msg: `Please review the following fields: ${msgError}` };
};

export {
  validateMail,
  validateLogin,
  validateConfirmPassword,
  validateProfile,
  validateNewUserRetail,
  validateNewUserWholesaler,
  validateAdministrators,
  validateVendor,
  validateRetail,
  validateWholesaler,
  validateProducts,
  validateInventoryAdd,
  validateInventoryTransfer,
  validateWarehouse,
  validateQuote,
  validateQuotePhoto,
  validateAddress,
  validateCreditCard,
  validateCarInsurance,
  validateCarInsuranceAux,
  validateFormReparation,
  validateInformation,
  validateInsurer
};