const AboutUsFooter = () => {
  return (
    <footer className="bg-ag-secondary">
      <div className="px-10 py-6 text-white border-b-4 border-red-500">
        <div className="pl-10 md:pl-3 lg:pl-5 xl:pl-32 pb-5">
          <span className="text-base font-semibold">Avondale Auto Glass</span>
        </div>
        <div className="pl-10 md:pl-3 lg:pl-5 xl:pl-32 grid md:gap-10 md:grid-cols-3">
          <div className="space-y-3">
            <div>
              <p className="text-sm font-semibold">Avondale Auto Glass #1 {'(GYR)'}:</p>
              <p className="text-xs">18503 W Van Buren St</p>
              <p className="text-xs">Goodyear, AZ 85338</p>
            </div>
            <div>
              <p className="text-sm font-semibold">Hours:</p>
              <p className="text-xs">Monday to Friday: 7am - 5pm</p>
              <p className="text-xs">Saturday: 7am - 4pm</p>
              <p className="text-xs">Sunday: Closed</p>
            </div>
            <div className="flex justify-start items-center space-x-2">
              <p className="text-sm font-semibold">Phone:</p>
              <p className="text-xs">623-337-3352</p>
            </div>
          </div>
          <div className="space-y-3">
            <div>
              <p className="text-sm font-semibold">Avondale Auto Glass #2 {'(PHX)'}:</p>
              <p className="text-xs">1730 E Brodway RD</p>
              <p className="text-xs">Phoenix, AZ 85040</p>
            </div>
            <div>
              <p className="text-sm font-semibold">Hours:</p>
              <p className="text-xs">Monday to Friday: 8am - 6pm</p>
              <p className="text-xs">Saturday: 7am - 4pm</p>
              <p className="text-xs">Sunday: 9am - 2pm</p>
            </div>
            <div className="flex justify-start items-center space-x-2">
              <p className="text-sm font-semibold">Phone:</p>
              <p className="text-xs">602-606-2605</p>
            </div>
          </div>
          <div className="space-y-3">
            <p className="text-sm font-semibold">Service Area:</p>
            <ul className="text-xs">
              <li>Phoenix, Buckeye, Goodyear,</li>
              <li>Tolleson, Avondale, El Mirage, Sun City,</li>
              <li>Glendale, Peoria, Surprise, Tempe,</li>
              <li>Chandler, Laveen, Cave Creek,</li>
              <li>Guadalupe, Care Free Youngtown,</li>
              <li>Paradise Valley Gilbert, Wickenburg,</li>
              <li>Scottsdale, Mesa, Litchfield Park,</li>
              <li>Verrado, Tonapah and Waddell.</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AboutUsFooter;