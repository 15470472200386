import { IoMdCloseCircle } from "react-icons/io";
import { alertOptions, formatCurrency, isOriginalGlass } from "../../../utils/Utilities";
import { useEffect, useState } from "react";
import { getInsurers } from "../../../services/Insurer";
import { toast } from "react-toastify";
import IconRetail from "../../../assets/icons/icon_glass.svg";

const ModalCalculateRetail = ({ itemReq, itemResp, onClose, onSelect }) => {
  const [isValid, setIsValid] = useState(false);
  const [isCalibration, setIsCalibration] = useState(false);
  const [valueResponse, setValueResponse] = useState({ total: 0, tax: 0 });

  const [values, setValues] = useState({
    kit: 0,
    labor: 0,
    list: 0,
    discount: 0,
    net: 0,
    services: 0,
    subtotal: 0,
    tax: 0,
    total: 0
  });

  const getServices = () => {
    let services = [];
    if(itemResp?.ceramicFull90){
      services.push("Ceramic Full Windshield Tint 90% Heat Rejection");
    }
    if(itemResp?.ceramicFull70){
      services.push("Ceramic Full Windshield Tint 70% Heat Rejection");
    }
    if(itemResp?.waterTreatment){
      services.push("Windshield Water Treatment");
    }
    if(itemResp?.windshieldVisorStrip){
      services.push("Windshield Visor Strip");
    }

    if(isCalibration){
      services.push("Calibration");
    }

    if(services.length === 0) return "Services";

    services = services.join(", ");
    return services;
  };

  useEffect(() => {
    if (itemReq !== null) {
      if (Number(itemReq?.price) > 0) {
        setIsValid(true);
        getInsurers().then(res => {
          if (res.status === 200) {
            const insurerDefault = res.data.find(elem => elem.name === "Default Insurer");
            if (insurerDefault) {
              const calibrationFound = itemReq.category.find(elemCal => elemCal.label === "Calibration");
              let calibrationValue = 0;
              let discountValue = 0;
              if (calibrationFound && calibrationFound.status) {
                calibrationValue = insurerDefault?.dualCalibration;
                setIsCalibration(true);
              }

              const serviceCF90 = itemResp?.ceramicFull90 ? 250 : 0;
              const serviceCF70 = itemResp?.ceramicFull70 ? 200 : 0;
              const serviceWatT = itemResp?.waterTreatment ? 50 : 0;
              const serviceWSVS = itemResp?.windshieldVisorStrip ? 50 : 0;
              const sumServices = Number(serviceCF70) + Number(serviceCF90) + Number(serviceWatT) + Number(serviceWSVS) + Number(calibrationValue);

              if (!isOriginalGlass(itemReq)) {
                discountValue = itemReq.heated === "Y" ? insurerDefault?.discountHeated : insurerDefault?.discountRegular;
              }

              const laborValue = itemReq.labor_time === 0 ? 450 : Number(itemReq?.labor_time) * Number(insurerDefault?.hour);
              const netValue = Number(itemReq?.price) - (Number(itemReq?.price) * (discountValue/100));
              const subTotalValue = netValue + Number(insurerDefault?.kitRegular) + laborValue + sumServices;
              const taxValue = Number(subTotalValue * (insurerDefault?.taxRate/100));
              const totalValue = subTotalValue + taxValue;

              setValueResponse({...valueResponse,
                total: totalValue < 260 ? 260 : totalValue,
                tax: taxValue
              });
              setValues({...values,
                kit: insurerDefault?.kitRegular,
                labor: laborValue,
                list: itemReq?.price,
                discount: discountValue,
                net: netValue,
                services: sumServices,
                subtotal: totalValue < 260 ? (260 - taxValue) : subTotalValue,
                tax: taxValue,
                total: totalValue < 260 ? 260 : totalValue
              });
            } else {
              toast.warning("An internal error has occurred, please contact the administrator", alertOptions);
            }
          }
        });
      } else {
        setIsValid(false);
      }
    }
  }, [itemReq]);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[700px] lg:w-[850px] h-[500px] xl:h-auto">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="w-full flex items-center justify-center rounded text-center">
                <div className="w-[95%]">
                  <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                    Finish Quote
                  </span>
                </div>
                <div className="w-[5%]">
                  <button
                    className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <IoMdCloseCircle />
                  </button>
                </div>
              </div>
              <div className="lg:flex lg:justify-center lg:items-center lg:space-x-1">
                <div className="w-full lg:w-auto m-0 md:m-2 lg:m-5 md:border pb-8 md:border-ag-secondary rounded-xl">
                  <div className="px-5 md:px-10 pb-2 pt-3 lg:pt-8">
                    {
                      isValid ?
                        <span className="text-ag-secondary-letter text-xs xl:text-sm font-semibold">
                          Would you like to continue with setting up your appointment?
                        </span>
                        :
                        <div className="pt-5 md:pt-0">
                          <span className="text-ag-secondary-letter text-sm xl:text-base font-semibold">
                            We can&apos;t find your glass, please call us
                          </span>
                        </div>
                    }
                  </div>
                  {
                    isValid ?
                      <div className="md:flex md:justify-center md:items-center m-auto px-5 pb-2 pt-5 space-y-3 md:space-y-0">
                        <div className="md:flex md:space-x-3 pb-5 md:pb-0 pr-0 md:pr-5">
                          <div className="px-5 md:px-2 lg:px-5 flex justify-center items-center">
                            <img className="w-[50px] m-auto" src={IconRetail} />
                          </div>
                          <div className="space-y-3">
                            <div className="text-left">
                              <div>
                                <span className="text-ag-secondary-letter text-xs xl:text-sm">
                                  {'('}{ String(itemReq.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(itemReq.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(itemReq.year).replace(/\s+/g, '').split(',').sort()[Number(String(itemReq.year).replace(/\s+/g, '').split(',').length-1)]}` : itemReq.year }{') '}
                                  { itemReq?.brand }{' '}
                                  { itemReq?.model }{' '}
                                  { itemReq?.type_car }
                                </span>
                              </div>
                              <div className="pt-2 space-y-1">
                                <div>
                                  <span className="text-xs xl:text-sm text-ag-secondary-letter font-semibold italic">
                                                                        Extra Services:
                                  </span>
                                </div>
                                <div className="space-y-1">
                                  {
                                    itemResp?.ceramicFull90 ?
                                      <p className="text-xs xl:text-sm text-ag-secondary-letter">
                                                                                Ceramic Full Windshield Tint 90% Heat Rejection
                                      </p>
                                      : null
                                  }
                                  {
                                    itemResp?.ceramicFull70 ?
                                      <p className="text-xs xl:text-sm text-ag-secondary-letter">
                                                                                Ceramic Full Windshield Tint 70% Heat Rejection
                                      </p>
                                      : null
                                  }
                                  {
                                    itemResp?.waterTreatment ?
                                      <p className="text-xs xl:text-sm text-ag-secondary-letter">
                                                                                Windshield Water Treatment
                                      </p>
                                      : null
                                  }
                                  {
                                    itemResp?.windshieldVisorStrip ?
                                      <p className="text-xs xl:text-sm text-ag-secondary-letter">
                                                                                Windshield Visor Strip
                                      </p>
                                      : null
                                  }
                                  {
                                    isCalibration ?
                                      <p className="text-xs xl:text-sm text-ag-secondary-letter">
                                                                                Calibration
                                      </p>
                                      : null
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul className="space-y-1">
                          <li className="grid grid-cols-2">
                            <div className="bg-zinc-200 w-28 py-2 text-center rounded-xl justify-self-end leading-none">
                              <span className="text-ag-secondary-letter text-xs font-semibold">
                                {getServices()}
                              </span>
                            </div>
                            <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl flex items-center justify-center">
                              <span className="text-xs">
                                { formatCurrency(itemResp?.paymentMethod === "PAY_ON_MY_WON" ? values.services : 0) }
                              </span>
                            </div>
                          </li>
                          <li className="flex justify-center items-center">
                            <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-ag-secondary-letter text-xs font-semibold">Subtotal</span>
                            </div>
                            <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-xs">
                                { formatCurrency(itemResp?.paymentMethod === "PAY_ON_MY_WON" ? values.subtotal : 0) }
                              </span>
                            </div>
                          </li>
                          <li className="flex justify-center items-center">
                            <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-ag-secondary-letter text-xs font-semibold">Tax</span>
                            </div>
                            <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-xs">
                                { formatCurrency(itemResp?.paymentMethod === "PAY_ON_MY_WON" ? values.tax : 0) }
                              </span>
                            </div>
                          </li>
                          <li className="flex justify-center items-center">
                            <div className="bg-zinc-200 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-ag-secondary-letter text-xs font-semibold">Total</span>
                            </div>
                            <div className="bg-zinc-100 w-28 px-5 py-2 text-center rounded-xl">
                              <span className="text-xs">
                                { formatCurrency(itemResp?.paymentMethod === "PAY_ON_MY_WON" ? values.total : 0) }
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      :
                      <div className="w-[85%] md:w-[90%] m-auto">
                        <div>
                          <p className="text-ag-secondary-letter text-sm xl:text-base">
                            At the moment we do not have the glass, please contact the administrator so
                            that they can assist you as soon as possible.
                          </p>
                        </div>
                        <div className="text-center md:text-left pt-5">
                          <a href="mailto:avondaleautoglass1@gmail.com" className="text-blue-800 text-xs md:text-sm">
                            avondaleautoglass1@gmail.com
                          </a>
                        </div>
                        <div className="text-center md:text-left">
                          <span className="pr-2 text-ag-secondary-letter text-sm xl:text-base font-semibold italic">
                            623-337-3352
                          </span>
                        </div>
                      </div>
                  }
                </div>
              </div>
              <div className="flex justify-end items-center space-x-5 pt-3 md:pt-0">
                <button
                  type="button"
                  className="px-10 md:px-20 py-3 bg-ag-primary-light text-white text-xs rounded-xl"
                  onClick={() => onClose(false)}
                >
                  Cancel
                </button>
                {
                  isValid ?
                    <button
                      type="button"
                      className="px-10 md:px-20 py-3 bg-green-600 text-white text-xs rounded-xl"
                      onClick={() => onSelect(valueResponse)}
                    >
                      Continue
                    </button>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default ModalCalculateRetail;